import { vanillaFade } from "../modules/tools";

class EgoModal {
    constructor({ element, internalNav, onOpen, onClose, lockScrollOnOpen }) {
        this.modal = element,
        this.lockScrollOnOpen = lockScrollOnOpen || true,
        this.box = element.querySelector('.ego-modal__box'),
        this.content = element.querySelector('.ego-modal__box__body-wrapper'),
        this.closeBtn = element.querySelector('.ego-modal__box__close-btn'),

        this.internalNav = internalNav,
        this.totalPages = this.box.querySelectorAll('.ego-modal__box__body--page').length,
        this.currentPage = this.box.querySelector('.ego-modal__box__body--page.--active'),
        this.currentPageNumber = this.currentPage ? parseInt(this.currentPage.dataset.page) : 1,
        this.pageChanging = false,

        this.onOpen = onOpen,
        this.onClose = onClose,
        this.state = false

        this.declareHandlers();
    }

    open(callback) {
        this.lockScroll();
        vanillaFade({
            element: this.modal, 
            enter: true, 
            duration: 250, 
            displayType: 'block', 
            callback: callback
        });
        setTimeout(() => {
            this.modal.classList.add('--open');
        }, 20);
        this.state = true;
        if (typeof this.onOpen === 'function') this.onOpen();
    }

    close(callback) {
        vanillaFade({
            element: this.modal, 
            enter: false, 
            duration: 250, 
            displayType: 'block', 
            callback: callback
        });
        this.modal.classList.remove('--open');
        this.lockScroll(false);
        this.state = false;
        if (typeof this.onClose === 'function') this.onClose();
    }

    lockScroll(lock = true) {
        if (!this.lockScrollOnOpen) return false;
        
        let body = document.getElementsByTagName('body')[ 0 ];
        if (lock) body.style.overflow = 'hidden';
        else body.style.removeProperty('overflow');
    }

    inyectContent(content, callback) {
        this.content.innerHTML = content;
        if (typeof callback === 'function') callback();
    }

    navToPage(page) {
        if (!this.pageChanging && parseInt(page) != this.currentPageNumber) {
            const nextPage = this.box.querySelector(`.ego-modal__box__body--page[data-page="${page}"]`);
            const modal = this;

            if (nextPage) {
                this.pageChanging = true;

                vanillaFade({
                    element: modal.currentPage, 
                    enter: false, 
                    time: 200,
                    callback: () => {
                        vanillaFade({
                            element: nextPage, 
                            enter: true, 
                            time: 200,
                            callback: () => {
                                modal.currentPage = nextPage;
                                modal.currentPageNumber = parseInt(page);
                                modal.pageChanging = false;
                            }
                        })
                    }
                });
            }
        }
    }

    nextPage() {
        if (this.currentPageNumber < this.totalPages) {
            this.navToPage(this.currentPageNumber + 1);
        }
    }

    prevPage() {
        if (this.currentPageNumber > 1) {
            this.navToPage(this.currentPageNumber - 1);
        }
    }

    declareHandlers() {
        const self = this;
        this.closeBtn?.addEventListener('click', () => self.close());
        this.modal.addEventListener('click', () => self.close());
        this.box.addEventListener('click', e => {
            if(e.target && !e.target.classList.contains('close-modal')){
                e.stopImmediatePropagation()
            }
        });

        // Internal nav
        this.modal.querySelectorAll('.nav-to-page').forEach(button => {
            button.addEventListener('click', btn => {
                const page = button.dataset.page;
                if (page === 'next') this.nextPage();
                else if (page === 'prev') this.prevPage();
                else this.navToPage(page);
            })
        });
    }
}

export default EgoModal;