import { millisToMinutesAndSeconds } from '../modules/tools';

export const setCountdowns = () => {
    const countdowns = document.querySelectorAll('.countdown');

    countdowns.forEach(countdown => {
        let counter = countdown.dataset.start || 60000;
        const timer = countdown.querySelector('.countdown__timer');
        const target = document.querySelector(countdown.dataset.target);
        const action = countdown.dataset.action;

        const timeValues = millisToMinutesAndSeconds(counter);
        timer.innerText = `${timeValues.minutes.toString().padStart(2, '0')}:${timeValues.seconds.toString().padStart(2, '0')}`;
        
        let timerInterval = setInterval(() => {
            counter -= 1000;
            const timeValues = millisToMinutesAndSeconds(counter);
            timer.innerText = `${timeValues.minutes.toString().padStart(2, '0')}:${timeValues.seconds.toString().padStart(2, '0')}`;
            
            if (counter === 0) {
                switch (action) {
                    case 'enable_button':
                        target.classList.remove('disabled');
                        break;
                
                    default:
                        break;
                }
                clearInterval(timerInterval);
            }
        }, 1000);
    })
}