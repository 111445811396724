export const alertFunctionality = () => {
    const alerts = document.querySelectorAll('.alert');

    if (alerts) {
        alerts.forEach(alert => {
            if (alert.classList.contains('--dismissible'))
                alert.addEventListener('click', () => dismiss(alert));

            if (alert.classList.contains('--timeout'))
                setTimeout(() => dismiss(alert), alert.dataset.timeout);
        });
    }
}

const dismiss = alert => {
    alert.classList.add('--close');
    setTimeout(() => {
        alert.classList.add('--hide');
        alert.classList.remove('--close');
    }, 200);
}