import axios from 'axios';
import EgoModal from '../components/ego-modal';

export function accordionProfile() {
    const acc = document.querySelectorAll(".profile__content .accordion-bg__item");
    const accInner = document.querySelectorAll(".profile__content .accordion-bg__inner");
    if(!acc.length && !accInner.length) return;
    
    const accInTabs = document.querySelectorAll(".profile__content .tabs-panel");
    if(accInTabs) {
      for (let i = 0; i < accInTabs.length; i++) {
        const firstItem = accInTabs[i].querySelector(".accordion-bg__item")
        const firstInner = accInTabs[i].querySelector(".accordion-bg__inner")
        
        firstItem?.classList.add("active")
        firstItem?.setAttribute('aria-expanded', "true");
        firstInner?.setAttribute('aria-hidden', "false");
      }
    } // Setea los primeros valores de cada tabpanel (el primero inicia abierto)

    acc.forEach((acc, index) => acc.addEventListener("click", () => accCb(index)));
    
    function accCb(index) {
      acc[index].classList.toggle("active");
      
      if (acc[index].classList.contains("active")){
        acc[index].setAttribute('aria-expanded', "true" );
        accInner[index].setAttribute('aria-hidden', "false");
      } else {
        acc[index].setAttribute('aria-expanded', "false" );
        accInner[index].setAttribute('aria-hidden', "true");
      }
    }
  
    for (let i = 0; i < accInner.length; i++) {
      accInner[i].addEventListener("click", function(e) {
        e.stopPropagation();
        // Evita que se cierre el acordeón al clickear el contenido
      });
    }
}

export function handleTabs() {
  const tabsPanel = document.querySelectorAll(".profile__content .tabs-panel");
  const tabsAnchor = document.querySelectorAll('.profile__content .tabs-list__anchor');
  if(!tabsPanel.length && !tabsAnchor.length) return;

  const hash = window.location.hash;
  const tabsInDom = []
  for (let i = 0; i < tabsPanel.length; i++){
    tabsInDom.push({
      label: tabsPanel[i].getAttribute("data-tab"),
      index: i
    })
  }
  const setInitialTabActived = (tabLabel) => {
    tabsInDom.forEach(tab => {
      if(tab.label.includes(tabLabel)){
        tabsAnchor[tab.index].parentElement.setAttribute('aria-selected', "true")
        tabsPanel[tab.index].setAttribute('aria-hidden', "false")
        tabsAnchor[tab.index].parentElement.classList.add("active");
        tabsPanel[tab.index].style.display = "flex";
      }  
    })
  }

  switch (true) {
    case hash.includes("mis-publicaciones") && tabsInDom.some(tab => tab.label.includes("mis-publicaciones")):
      setInitialTabActived("mis-publicaciones")
      break;
    case hash.includes("mis-compras") && tabsInDom.some(tab => tab.label.includes("mis-compras")):
      setInitialTabActived("mis-compras")
      break;
    case hash.includes("mis-favoritos") && tabsInDom.some(tab => tab.label.includes("mis-favoritos")):
      setInitialTabActived("mis-favoritos")
      break;
    case hash.includes("en-mi-red") && tabsInDom.some(tab => tab.label.includes("en-mi-red")):
      setInitialTabActived("en-mi-red")
      break
    default:
      // Setea los primeros valores
      tabsAnchor[0].parentElement.setAttribute('aria-selected', "true")
      tabsPanel[0].setAttribute('aria-hidden', "false")
      tabsAnchor[0].parentElement.classList.add("active");
      tabsPanel[0].style.display = "flex";
      break;
  }
  
  tabsAnchor.forEach((btn, index) => btn.addEventListener("click", () => itemCb(index)));

  function itemCb(index) {
    for (let i = 0; i < tabsPanel.length; i++) {
      tabsPanel[i].style.display = "none";
      tabsPanel[i].setAttribute("aria-hidden", "true");
      tabsPanel[index].style.display = "flex"
      tabsPanel[index].setAttribute("aria-hidden", "false");
    }
    for (let i = 0; i < tabsAnchor.length; i++) {
      tabsAnchor[i].parentElement.classList.remove("active");
      tabsAnchor[i].parentElement.setAttribute("aria-selected", "false");
      tabsAnchor[index].parentElement.classList.add("active");
      tabsAnchor[index].parentElement.setAttribute("aria-selected", "true");
    }
  }
}

export const handleVerifyModal = () => {
  const verifyModalElement = document.getElementById('verifyEmailModal');
  const successModalElement = document.getElementById('successModal');
  const saleConfirmation = document.getElementById('saleConfirmation');
  const saleMade = document.getElementById('saleMade');
  const certification = document.getElementById('certification');
  const comissionsModalElement = document.getElementById('comissionsModal');

  const openDialogVerify = document.getElementById('openDialogVerify');
  const openDialogSuccess = document.getElementById('openDialogSuccess');
  const openDialogSuccess2 = document.getElementById('openDialogSuccess2');
  const openDialogConfirmation = document.getElementById('openDialogConfirmation');
  const openDialogSaleMade = document.getElementById('openDialogSaleMade');
  const openDialogCertification = document.getElementById('openDialogCertification');
  const openDialogComissions = document.getElementById('openDialogComissions');


  if(openDialogSuccess) {
    openDialogSuccess.addEventListener('click', () => {
      if (successModalElement) {
        const successModal = new EgoModal({
            element: successModalElement
        });
        successModal.open();
      };
    });
  } if(openDialogSuccess2) {
    openDialogSuccess2.addEventListener('click', () => {
      if (successModalElement) {
        const successModal = new EgoModal({
            element: successModalElement
        });
        successModal.open();
      };
    });
  } if(openDialogVerify) {
    openDialogVerify.addEventListener('click', () => {
      if (verifyModalElement) {
        const verifyEmailModal = new EgoModal({
            element: verifyModalElement
        });
        verifyEmailModal.open();
      };
    });
  } if(openDialogConfirmation) {
    openDialogConfirmation.addEventListener('click', () => {
      if (saleConfirmation) {
        const saleConfirmationModal = new EgoModal({
            element: saleConfirmation
        });
        saleConfirmationModal.open();
      };
    });
  } if(openDialogSaleMade) {
    openDialogSaleMade.addEventListener('click', () => {
      if (saleMade) {
        const saleMadeModal = new EgoModal({
            element: saleMade
        });
        saleConfirmation.style.display = "none";
        saleMadeModal.open();
      };
    });
  } if(openDialogCertification) {
    openDialogCertification.addEventListener('click', () => {
      if (certification) {
        const certificationModal = new EgoModal({
            element: certification
        });
        certificationModal.open();
      };
    });
  } if(openDialogComissions) {
    openDialogComissions.addEventListener('click', () => {
      if (comissionsModalElement) {
        const comissionsModal = new EgoModal({
            element: comissionsModalElement
        });
        comissionsModal.open();
      };
    });
  }
}

export const renewExpirationDate = () => {
  const expirationBox = document.querySelectorAll(".car-list__expiration-box");
  
  if(expirationBox){
    expirationBox.forEach(box =>{
      const button = box.querySelector(".btn-small")
      const card = box.closest(".car-list");
      const state = card.querySelector(".car-list__content__state");
      const stateText = card.querySelector(".car-list__content__state em");  

      button.addEventListener("click", () => {
        const licensePlate = button.getAttribute("license-plate")
        const alert = document.querySelector(".tabs").querySelector(".alert")
        button.classList.add('--loading')

        axios.post('/api/store/vehicle_renew_publication/', {
          'license_plate': licensePlate
        })
        .then(response => {
          if (response.status === 200) {
            card.classList.remove("--pre-expired");
            box.classList.add("--renovada");
            state.dataset.state = "published";
            stateText.innerHTML = "Publicado";
            alert.classList.remove("--hide")
            alert.addEventListener("click", () => {
              window.location.reload()
            })
            button.classList.remove('--loading')
          }
        })
        .catch(error => {
          console.error(error);
          button.classList.remove('--loading')
        });
      })
    })
  }
}

export function accordionComissionsToggle() {
    const acc = document.getElementsByClassName("accordion-comissions");
    
    if(acc){
      for (let i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
          this.classList.toggle("active");
      
          const panel = this.nextElementSibling;
          const plus = this.querySelector('.toggle-plus')
          const minus = this.querySelector('.toggle-minus')
  
          if (panel.style.display === "block") {
            panel.style.display = "none";
            plus.style.display = "block";
            minus.style.display = "none";
          } else {
            panel.style.display = "block";
            plus.style.display = "none";
            minus.style.display = "block";
          }
        });
      }
    }
}
