import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


export const uppserSectionSlider = () => {
    const swiper = new Swiper("#upper-section-swiper", {
        modules: [Navigation, Pagination],
        slidesPerView: 2,
        spaceBetween: 10,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 15
            },
            480: {
                slidesPerView: 3,
                spaceBetween: 15
            },
            640: {
                slidesPerView: 5,
                spaceBetween: 15
            },
        },
    });
}

export const featuredSectionSlider = () => {
    const swiper = new Swiper("#featured-section-swiper", {
        modules: [Navigation, Pagination],
        slidesPerView: 1.1,
        centeredSlides: true,
        spaceBetween: 16,
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".featured .swiper-button-next",
            prevEl: ".featured .swiper-button-prev",
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 16,
                loop: false,
                centeredSlides: false,
                centerInsufficientSlides: true
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 16,
                loop: false,
                centeredSlides: false,
                centerInsufficientSlides: true
            },
            1280: {
                slidesPerView: 4,
                spaceBetween: 16,
                loop: false,
                centeredSlides: false,
                centerInsufficientSlides: true
            },
        },
    });
}

export const mostSeenSectionSlider = () => {
    const swiper = new Swiper("#most-seen-section-swiper", {
        modules: [Navigation, Pagination],
        slidesPerView: 1.1,
        centeredSlides: true,
        spaceBetween: 16,
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".most-seen .swiper-button-next",
            prevEl: ".most-seen .swiper-button-prev",
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 16,
                loop: false,
                centeredSlides: false,
                centerInsufficientSlides: true
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 16,
                loop: false,
                centeredSlides: false,
                centerInsufficientSlides: true
            },
            1280: {
                slidesPerView: 4,
                spaceBetween: 16,
                loop: false,
                centeredSlides: false,
                centerInsufficientSlides: true
            },
        },
    });
}