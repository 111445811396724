export const handleEllipsis = () => {
    const ellipsis = document.querySelectorAll('.ellipsis');

    if (ellipsis) {
        window.addEventListener('click', e => {
            document.querySelectorAll('.ellipsis.--open').forEach(ellip => ellip.classList.remove('--open') );
        });

        ellipsis.forEach(ellip => {
            ellip.querySelector('.ellipsis__button').addEventListener('click', e => {
                e.stopPropagation();
                ellip.classList.toggle('--open');
            });

            ellip.querySelector('.ellipsis__dropdown').addEventListener('click', e => {
                e.stopPropagation();
            });
        });
    }
}