const ValidationMessages = {
    first_name: {
        empty: 'El nombre es requerido',
    },
    last_name: {
        empty: 'El apellido es requerido',
    },
    license_plate: {
        empty: 'La Patente es requerida',
        invalid: 'La Patente ingresada no es válida',
    },
    rut: {
        empty: 'El RUT es requerido',
        invalid: 'El RUT ingresado no es válido',
    },
    email: {
        empty: 'El email es requerido',
        invalid: 'El email ingresado no es válido',
    },
    phone_number: {
        empty: 'El teléfono es requerido',
        invalid: 'El numero de teléfono ingresado no es válido',
    },
    message: {
        empty: 'El mensaje es requerido',
    },
    password: {
        empty: 'Ingrese una contraseña',
        invalid: 'Mínimo 8 caracteres y al menos un número y una letra.'
    },
    password_repeat: {
        empty: 'Repita la contraseña',
        unequal: 'Las contraseñas deben coincidir',
    },
    cuil: {
        empty: 'Required field',
        min: 'Minimum 11 digits',
        invalid: 'Invalid number',
    },
    default: {
        empty: 'Campo requerido'
    }
};

export default ValidationMessages;