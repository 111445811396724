export const mobileMenuFunctionality = () => {
    let active = false
    const animationTime = 300;
    
    const toggleButton = document.querySelector('#toggler');
    const secondaryNav = document.querySelector('#secondary-nav');
    const htmlElement = document.querySelector('html');
    const bodyElement = document.querySelector('body');

    if(toggleButton && secondaryNav){
        toggleButton.addEventListener('click', () => {
            if(!active){
                toggleButton.classList.add('active');
                secondaryNav.classList.add('open');
                htmlElement.classList.add('no-scroll');
                bodyElement.classList.add('no-scroll');
            } else {
                toggleButton.classList.remove('active');
                secondaryNav.classList.remove('open');
                setTimeout(() => {
                    htmlElement.classList.remove('no-scroll');
                    bodyElement.classList.remove('no-scroll');
                }, animationTime);
            }
            active = !active;
        });
    }
}