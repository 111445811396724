export const handlePlanSelectors = () => {
    const selectors = document.querySelectorAll('.plan-selector');
    
    selectors.forEach(selector => {
        selector.querySelectorAll('.plan-selector__button')
            .forEach(btn => {
                btn.addEventListener('click', e => {
                    if (!btn.classList.contains('--selected')) {
                        let selected = selector.querySelector('.plan-selector__button.--selected');
                        if (selected) selected.classList.remove('--selected');
                        btn.classList.add('--selected');
                    }
                });
            });
    
        selector.querySelectorAll('.toggle-description')
            .forEach(btn => {
                btn.addEventListener('click', e => {
                    e.preventDefault();
                    e.stopPropagation();
                    const description = btn.parentElement.nextElementSibling;
                    const height = description.scrollHeight;
                    if (description.classList.contains('--open')) {
                        description.classList.remove('--open');
                        description.style.maxHeight = '0px';
                        btn.innerHTML = btn.dataset.text;
                    } else {
                        description.classList.add('--open');
                        description.style.maxHeight = height + 'px';
                        btn.innerHTML = 'Cerrar';
                    }
                });
            });
    });
}