import axios from 'axios';
import EgoModal from '../components/ego-modal';
import { filterMoneyAmount } from '../modules/tools';

////////////////////
// DELETE VEHICLE
export const ownerVehicleDelete = () => {
    const deleteModals = {};
    document.querySelectorAll('.vehicle-delete-modal').forEach(modal => {
        deleteModals[modal.id] = new EgoModal({
            element: modal
        });
    });

    document.querySelectorAll('.open-delete-vehicle-modal').forEach(btn => {
        btn.addEventListener('click', e => {
            e.preventDefault();
            const modalKey = `vehicleDeleteModal${btn.dataset.pk}`;
            if (deleteModals[modalKey]) deleteModals[modalKey].open();
        });
    });

    document.querySelectorAll('.delete-vehicle-confirmation').forEach(btn => {
        btn.addEventListener('click', e => {
            e.stopImmediatePropagation();
            deleteVehicle(btn);
        });
    });
}

const deleteVehicle = btn => {
    btn.classList.add('loading');
    document.body.classList.add('block');

    axios.delete('/api/store/delete_vehicle/', { data: { id: btn.dataset.pk } })
        .then(resp => window.location.href = btn.dataset.redirect)
        .catch(err => console.log(err))
        .finally(() => {
            btn.classList.remove('loading');
            document.body.classList.remove('block');
        })
}


////////////////////////
// CANCEL MATCH
export const userMatchCancellation = () => {
    const cancelModals = {};
    document.querySelectorAll('.match-cancellation-modal').forEach(modal => {
        cancelModals[modal.id] = new EgoModal({
            element: modal
        });
        const cancelMatchBtn = modal.querySelector('.cancel-match-confirmation');
        const reason = modal.querySelector('textarea[name="cancellation_reason"]');
        
        reason?.addEventListener('input', () => enableCancelBtn(cancelMatchBtn, reason));
        reason?.addEventListener('paste', () => enableCancelBtn(cancelMatchBtn, reason));

        cancelMatchBtn?.addEventListener('click', e => {
            e.stopImmediatePropagation();
            cancelMatch(e.target, reason);
        });
    });

    document.querySelectorAll('.open-match-cancellation-modal').forEach(btn => {
        btn.addEventListener('click', e => {
            e.preventDefault();
            const modalKey = `matchCancellationModal${btn.dataset.pk}`;
            if (cancelModals[modalKey]) cancelModals[modalKey].open();
        });
    });
}

const enableCancelBtn = (cancelMatchBtn, reason) => {
    if (reason.value.length >= 5) cancelMatchBtn.classList.remove('disabled');
    else cancelMatchBtn.classList.add('disabled');
}

const cancelMatch = (btn, reason) => {
    btn.classList.add('loading');
    document.body.classList.add('block');

    axios.post('/api/store/cancel_match/', { id: btn.dataset.pk, message: reason.value })
        .then(resp => window.location.href = btn.dataset.redirect)
        .catch(err => console.log(err))
        .finally(() => {
            btn.classList.remove('loading');
            document.body.classList.remove('block');
        })
}


//////////////////////////////
// DLR CERTIFICATION MANAGEMENT
export const DLRCertificationManage = () => {
    const certificationModals = {};
    document.querySelectorAll('.certification-management-modal').forEach(modal => {
        certificationModals[modal.id] = new EgoModal({
            element: modal
        });
    });

    document.querySelectorAll('.open-certification-manage-modal').forEach(btn => {
        btn.addEventListener('click', e => {
            e.preventDefault();
            const modalKey = `certificationManagementModal${btn.dataset.pk}`;
            if (certificationModals[modalKey]) certificationModals[modalKey].open();
        });
    });

    document.querySelectorAll('.dlr-certification-set').forEach(btn => {
        btn.addEventListener('click', e => {
            setCertification(btn);
        });
    });
}

const setCertification = (btn) => {
    btn.classList.add('loading');
    document.body.classList.add('block');

    axios.post('/api/store/change_certification_status/', { 
        license_plate: btn.dataset.license, 
        status: btn.dataset.status
    })
        .then(resp => window.location.reload())
        .catch(err => console.log(err))
        .finally(() => {
            btn.classList.remove('loading');
            document.body.classList.remove('block');
        })
}


//////////////////////////////
// MATCH CONTACT DATA
export const getContactData = () => {
    const contactDataModals = {};
    document.querySelectorAll('.contact-data-modal').forEach(modal => {
        contactDataModals[modal.id] = new EgoModal({
            element: modal
        });
    });

    document.querySelectorAll('.open-contact-data-modal').forEach(btn => {
        btn.addEventListener('click', e => {
            e.preventDefault();
            const modalKey = `contactDataModal${btn.dataset.pk}`;
            if (contactDataModals[modalKey]) contactDataModals[modalKey].open();
        });
    });
}


///////////////////
// HANDLE OFFERS
export const userHandleOffers = () => {
    const handleOffersModals = {};
    document.querySelectorAll('.handle-offers-modal').forEach(modal => {
        handleOffersModals[modal.id] = new EgoModal({
            element: modal
        });

        // ACCEPT
        const acceptPage = modal.querySelector('.accept-page'),
            acceptPageNumber = acceptPage.dataset.page,
            acceptsAmount = acceptPage.querySelector('.amount'),
            acceptsDealer = acceptPage.querySelector('.dealer'),
            acceptConfirmationBtn = modal.querySelector('.accept-offer-confirmation');

        modal.querySelectorAll('.user-accept-offer').forEach(accept => {
            const dataParent = accept.parentElement;
            accept.addEventListener('click', () => {
                acceptsAmount.innerHTML = filterMoneyAmount(dataParent.dataset.amount);
                acceptsDealer.innerHTML = dataParent.dataset.dealer;
                acceptConfirmationBtn.dataset.pk = dataParent.dataset.pk;
                handleOffersModals[modal.id].navToPage(acceptPageNumber);
            });
        });
        
        // REJECT
        const rejectPage = modal.querySelector('.reject-page'),
            rejectPageNumber = rejectPage.dataset.page,
            rejectAmount = rejectPage.querySelector('.amount'),
            rejectDealer = rejectPage.querySelector('.dealer'),
            rejectConfirmationBtn = modal.querySelector('.reject-offer-confirmation');

        modal.querySelectorAll('.user-reject-offer').forEach(reject => {
            const dataParent = reject.parentElement;
            reject.addEventListener('click', () => {
                rejectAmount.innerHTML = filterMoneyAmount(dataParent.dataset.amount);
                rejectDealer.innerHTML = dataParent.dataset.dealer;
                rejectConfirmationBtn.dataset.pk = dataParent.dataset.pk;
                handleOffersModals[modal.id].navToPage(rejectPageNumber);
            });
        });

        modal.querySelector('.accept-offer-confirmation').addEventListener('click', e => {
            if (e.target.dataset.pk) acceptOffer(e.target, () => handleOffersModals[modal.id].nextPage());
        });

        modal.querySelector('.reject-offer-confirmation').addEventListener('click', e => {
            console.log(e.target.dataset);
            if (e.target.dataset.pk) rejectOffer(e.target, () => {
                document.getElementById('offer' + e.target.dataset.pk).classList.add('--rejected');
                handleOffersModals[modal.id].navToPage(1);
            });
        });

        modal.querySelector('.got-it').addEventListener('click', e => window.location.reload());
    });
    
    document.querySelectorAll('.open-profile-offers-modal').forEach(btn => {
        btn.addEventListener('click', e => {
            e.preventDefault();
            const modalKey = `profileOffersModal${btn.dataset.pk}`;
            if (handleOffersModals[modalKey]) handleOffersModals[modalKey].open();
        });
    });
}

const acceptOffer = (btn, callback) => {
    btn.classList.add('loading');
    document.body.classList.add('block');

    axios.post('/api/store/accept_offer/', { 
        id: btn.dataset.pk
    })
        .then(resp => {
            if (typeof callback === 'function') callback();
        })
        .catch(err => console.log(err))
        .finally(() => {
            btn.classList.remove('loading');
            document.body.classList.remove('block');
        })
}

const rejectOffer = (btn, callback) => {
    btn.classList.add('loading');
    document.body.classList.add('block');

    axios.post('/api/store/reject_offer/', { 
        id: btn.dataset.pk,
        message: 'Oferta rechazada por el usuario.'
    })
        .then(resp => {
            if (typeof callback === 'function') callback();
        })
        .catch(err => console.log(err))
        .finally(() => {
            btn.classList.remove('loading');
            document.body.classList.remove('block');
        })
}


////////////////////////
// DLR Match Confirmation
export const DLRMatchConfirmation = () => {
    const matchConfirmationModals = {};
    document.querySelectorAll('.match-confirmation-modal').forEach(modal => {
        matchConfirmationModals[modal.id] = new EgoModal({
            element: modal
        });

        modal.querySelector('.dlr-confirm-match').addEventListener('click', e => {
            e.preventDefault();
            if (e.target.dataset.pk) confirmMatch(e.target, () => matchConfirmationModals[modal.id].nextPage());
        });

        modal.querySelector('.reload-page').addEventListener('click', e => {
            e.preventDefault();
            window.location.reload();
        });
    });

    document.querySelectorAll('.open-match-confirmation-modal').forEach(btn => {
        btn.addEventListener('click', e => {
            e.preventDefault();
            const modalKey = `matchConfirmationModal${btn.dataset.pk}`;
            if (matchConfirmationModals[modalKey]) matchConfirmationModals[modalKey].open();
        });
    });
}

const confirmMatch = (btn, callback) => {
    btn.classList.add('loading');
    document.body.classList.add('block');

    axios.post('/api/store/complete_match/', { 
        id: btn.dataset.pk
    })
        .then(resp => {
            if (typeof callback === 'function') callback();
        })
        .catch(err => console.log(err))
        .finally(() => {
            btn.classList.remove('loading');
            document.body.classList.remove('block');
        })
}


///////////////////
// HANDLE FAVORITES

export const handleFavorite = () => {
    const favoriteBtns = document.querySelectorAll(".favorite-btn");
    const alert = document.querySelector(".status-favorite");

    if (favoriteBtns) {
        favoriteBtns.forEach((btn) =>
            btn.addEventListener("click", (e) => {
                const action = btn.dataset.action;
                if (action == "add-favorite") {
                    addToFavorites(btn, alert);
                } else if (action == "delete-favorite") {
                    deleteFromFavorite(btn, alert);
                }
            })
        );
    }
};

const addToFavorites = (btn, alert) => {
    axios
        .post("/api/store/add_favorite/", { id: btn.dataset.pk })
        .then((resp) => {
            btn.classList.add("filled");
            btn.setAttribute("data-action", "delete-favorite"); 

            const msg = `Agregaste esta publicación a tus favoritos.`
            showAlert(alert, msg)
        })
        .catch((err) => console.log(err))
};

const deleteFromFavorite = (btn, alert) => {
    const vehicle = btn.dataset.vehicle;
    axios
        .delete("/api/store/delete_favorite/", {data: { id: btn.dataset.pk }})
        .then((resp) => {
            btn.classList.remove("filled");
            btn.setAttribute("data-action", "add-favorite");
            
            const msg = `Eliminaste la publicación del Toyota ${vehicle} de tus favoritos`
            showAlert(alert, msg)
        })
        .catch((err) => console.log(err))
};

const showAlert = (alert, msg) => {
    if (msg) alert.querySelector(".message").innerText = msg

    const favButton = document.querySelector(".favorite-btn")
    alert.classList.remove("--hide")
    favButton.disabled = true

    
    setTimeout(() => {
        alert.classList.add('--close');
        setTimeout(() => {
            alert.classList.add('--hide');
            alert.classList.remove('--close');
        }, 200);
        favButton.disabled = false
    }, 3000);
}