import EgoForm from "../components/ego-form";
import { filterNumber, filterMoneyAmount, fillDataLayer } from "../modules/tools";

export const filtersFunctionality = () => {
    ///////////////////
    // FORM desk
    const filtersForm = document.getElementById('filtersForm');
    if (filtersForm) handleDesktopFilters(filtersForm);

    ///////////////////
    // MOBILE FORM
    const filtersModal = document.querySelector('.catalog__grid__filters-mob');
    if (filtersModal) handleMobileFilters(filtersModal);
}


const handleDesktopFilters = form => {
    const catalogfiltersForm = new EgoForm({
        element: form,
        submitType: 'POST',
        showMessages: false,
        onBeforeSubmit: () => {
            form.querySelector('[name="price_min"]').value = filterNumber(form.querySelector('[name="price_min"]').value)
            form.querySelector('[name="price_max"]').value = filterNumber(form.querySelector('[name="price_max"]').value)
            form.querySelector('[name="kilometers_min"]').value = filterNumber(form.querySelector('[name="kilometers_min"]').value)
            form.querySelector('[name="kilometers_max"]').value = filterNumber(form.querySelector('[name="kilometers_max"]').value)
        }
    });

    document.querySelector('.filters-full-clean')
            .addEventListener('click', () => { 
                catalogfiltersForm.reset();
            });
    
    document.querySelectorAll('.filter-form--partial-clean')
            .forEach(cleanButton => {
                cleanButton.addEventListener('click', e => {
                    const fieldGroup = cleanButton.parentElement.parentElement;
                    fieldGroup.querySelectorAll('.ego-form__field').forEach(field => {
                        if (field.classList.contains('--select')) catalogfiltersForm.resetSelectField(field);
                        else if (field.classList.contains('--radio')) catalogfiltersForm.resetRadioField(field);
                        else catalogfiltersForm.resetField(field)
                    });
                });
            });

    // ORDER mask select
    document.querySelector('input[name="order_mask"]')
            .addEventListener('value-change', e => {
                document.querySelector('input[name="order"]').value = e.target.value;
                form.submit();
            });
}


const handleMobileFilters = modal => {
    const filtersFormMob = document.getElementById('filtersFormMob');
    const catalogfiltersFormMob = new EgoForm({
        element: filtersFormMob,
        submitType: 'POST',
        showMessages: false,
        onBeforeSubmit: () => {
            filtersFormMob.querySelector('[name="price_min"]').value = filterNumber(filtersFormMob.querySelector('[name="price_min"]').value)
            filtersFormMob.querySelector('[name="price_max"]').value = filterNumber(filtersFormMob.querySelector('[name="price_max"]').value)
            filtersFormMob.querySelector('[name="kilometers_min"]').value = filterNumber(filtersFormMob.querySelector('[name="kilometers_min"]').value)
            filtersFormMob.querySelector('[name="kilometers_max"]').value = filterNumber(filtersFormMob.querySelector('[name="kilometers_max"]').value)
            handleMobileDataLayer(filtersFormMob)
        }
    });


    document.querySelector('.filters-mob-full-clean')
            .addEventListener('click', () => { 
                catalogfiltersFormMob.reset();
                catalogfiltersFormMob.submit();
            });


    document.querySelectorAll('.open-filters-modal')
        .forEach(btn => {
            btn.addEventListener('click', () => {
                modal.classList.add('--open');
                document.body.classList.add('no-scroll');
            });
        });
    
    document.querySelectorAll('.close-filters-modal')
        .forEach(btn => {
            btn.addEventListener('click', () => {
                modal.classList.remove('--open');
                document.body.classList.remove('no-scroll');
            });
        });


    ///////////
    // TABS
    const tabs = document.querySelector('.filters-mob__tabs');
    if (tabs) handleTabsBehavior(tabs);


    //////////
    // BADGES
    document.querySelectorAll('.filter-badge')
            .forEach(badge => {
                badge.addEventListener('click', e => {
                    const field = document.getElementById(badge.dataset.field + '_field');
                    if (field) {
                        if (field.classList.contains('--select')) catalogfiltersFormMob.resetSelectField(field);
                        else if (field.classList.contains('--radio')) catalogfiltersFormMob.resetRadioField(field);
                        else catalogfiltersFormMob.resetField(field);
                        
                        catalogfiltersFormMob.submit();
                    }
                });
            });
}


const handleTabsBehavior = tabs => {
    tabs.addEventListener('click', e => {
        if (e.target.classList.contains('tab-trigger') && e.target.checked) {
            e.preventDefault();
            tabs.querySelectorAll('.tab-trigger:checked').forEach(trigger => trigger.checked = false);
            setTimeout(() => {
                e.target.checked = true;
            }, 1);
        }
    });
}

const handleMobileDataLayer = form => {
    const selectedTransmission = [];
    const selectedFuel = [];
    const selectedCertificates = [];

    const checkedTransmissionTypes = form.querySelectorAll('input[type="checkbox"][name="transmission_type"]:checked')
    const checkedFuelTypes = form.querySelectorAll('input[type="checkbox"][name="fuel_type"]:checked')
    const checkedCertificates = form.querySelectorAll('input[type="checkbox"][name="is_certified"]:checked, input[type="checkbox"][name="is_pre_certified"]:checked')

    checkedTransmissionTypes.forEach(e => selectedTransmission.push(e.parentNode.innerText))
    checkedFuelTypes.forEach(e => selectedFuel.push(e.parentNode.innerText))
    checkedCertificates.forEach(e => selectedCertificates.push(e.parentNode.innerText))

    fillDataLayer({
        'event': 'filtro_compra',
        'vehículo_tipo': form.querySelector('input[type="radio"][name="body_type"]:checked')?.dataset?.value,
        'vehículos_modelo': form.querySelector('input[type="radio"][name="model"]:checked')?.dataset?.value,
        'precio_desde': form.querySelector('[name="price_min"]').value,
        'precio_hasta': form.querySelector('[name="price_max"]').value,
        'km_desde': form.querySelector('[name="kilometers_min"]').value,
        'km_hasta': form.querySelector('[name="kilometers_max"]').value,
        'año_desde': form.querySelector('[name="year_min"]').value,
        'año_hasta': form.querySelector('[name="year_max"]').value,
        'ubicación': form.querySelector('input[type="radio"][name="branch__city__region"]:checked')?.dataset?.value,
        'transmisión': selectedTransmission.length == 0 ? '' : selectedTransmission,
        'combustible': selectedFuel.length == 0 ? '' : selectedFuel,
        'certificado_toyota': selectedCertificates.length == 0 ? '' : selectedCertificates,
        'colores': form.querySelector('input[type="radio"][name="color"]:checked')?.dataset?.value || ''
    })
}

export const viewItemList = () => {
    const results = document.querySelector('.results_data-set')

    if(results) {
        const currentURL = window.location.href;
        const urlParams = new URLSearchParams(new URL(currentURL).search);
        const pageNumber = urlParams.get('page') || 1;
    
        const list = JSON.parse(results.dataset.set);
        dataLayer.push({ ecommerce: null })
        fillDataLayer({
            'event': 'view_item_list',
            'ecommerce': {
                'item_list_id': 'comprar',
                'item_list_name': 'comprar',
                'items': list.map((item, index) => (
                    {
                        'item_id': item.id,
                        'item_name': item.model + ' ' + item.version,
                        'affiliation': item.branch_name,
                        'index': index + 1,
                        'item_brand': item.model,
                        'item_category': item.body_type,
                        'item_category2': item.version,
                        'item_category3': item.year,
                        'item_list_id': pageNumber || 1,
                        'item_list_name': 'comprar',
                        'item_variant': item.color,
                        'location_id': item.branch_id,
                        'price': item.price,
                        'quantity': 1
                    }
                ))
            }
        })
    }
}