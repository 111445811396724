import EgoForm from "../components/ego-form";
import { getCookie } from "./tools";
import axios from 'axios';
import { filterNonDigits } from '../modules/tools';

// Requests default headers
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-CSRFToken'] = getCookie('csrftoken');


export const initForms = () => {
    const formElements = {
        login: document.getElementById('loginForm'),
        register: document.getElementById('registerForm'),
        socialRegister: document.getElementById('socialRegisterForm'),
        resetRequest: document.getElementById('passwordResetRequestForm'),
        resetForm: document.getElementById('passwordResetForm'),
        resetForm2: document.getElementById('passwordResetForm2'),
        changeEmail: document.getElementById('chanegEmailForm'),
        changeEmail2: document.getElementById('chanegEmailForm2'),
        licensePlate: document.getElementById('licensePlateForm'),
    };

    if (formElements.login) {
        const loginForm = new EgoForm({
            element: formElements.login,
            submitType: 'POST',
            showMessages: false
        });
    }

    if (formElements.register) {
        const registerForm = new EgoForm({
            element: formElements.register,
            submitType: 'POST',
            showMessages: false
        });
    }

    if (formElements.socialRegister) {
        const socialRegisterForm = new EgoForm({
            element: formElements.socialRegister,
            submitType: 'POST',
            showMessages: false
        });
    }

    if (formElements.resetRequest) {
        const resetRequest = new EgoForm({
            element: formElements.resetRequest,
            submitType: 'POST',
            showMessages: false
        });
    }

    if (formElements.resetForm) {
        const resetForm = new EgoForm({
            element: formElements.resetForm,
            submitType: 'POST',
            showMessages: false
        });
    }

    if (formElements.resetForm2) {
        const resetForm2 = new EgoForm({
            element: formElements.resetForm2,
            submitType: 'POST',
            showMessages: false
        });
    }

    if (formElements.changeEmail) {
        const changeEmail = new EgoForm({
            element: formElements.changeEmail,
            submitType: 'POST',
            showMessages: false
        });
    }

    if (formElements.changeEmail2) {
        const changeEmail2 = new EgoForm({
            element: formElements.changeEmail2,
            submitType: 'POST',
            showMessages: false
        });
    }

    if (formElements.licensePlate) {
        const licensePlate = new EgoForm({
            element: formElements.licensePlate,
            submitType: 'POST',
            showMessages: false
        });
    }

    const rutMask = document.querySelector('[name="rut_mask"]');
    const rut = document.querySelector('[name="rut"]');
    if (rut && rutMask) {
        rutMask.addEventListener('input', e => {
            rut.value = filterNonDigits(rutMask.value);
        });
        rutMask.addEventListener('paste', e => {
            rut.value = filterNonDigits(rutMask.value);
        });
    }
}

export const searchableRadioButtonsGroup = () => {
    const groups = document.querySelectorAll('.ego-form__field.--radio.--searchable')
    groups.forEach(group => {
        const radios = group.querySelectorAll('.ego-form__field__radio');
        const empty = group.querySelector('.ego-form__field__search .empty');
        const options = radios.length;

        group.querySelector('.ego-form__field__search input')
            .addEventListener('input', e => {
                let count = 0;
                radios.forEach(radio => {
                    if (radio.dataset.value.toLowerCase().indexOf(e.target.value.toLowerCase()) === -1) {
                        radio.classList.add('--hidden');
                        radio.querySelector('.ego-form__control').checked = false;
                        ++count;
                    } else {
                        radio.classList.remove('--hidden');
                    }
                });
                if (count >= options) empty.classList.add('--show');
                else empty.classList.remove('--show');
            });
    });
}


export const resendVerificationEmailHandler = () => {
    const resendButton = document.querySelector('.resend-email__button');

    if (resendButton) {
        const url = resendButton.dataset.url;
        resendButton.addEventListener('click', async e => {
            e.preventDefault();
            axios.post(url)
                .then(function (response) {
                    window.location.href = (response.status === 200) ? 
                        window.location.href+'?resend=true' :
                        window.location.href+'?resend_error=true';
                })
                .catch(function (error) {
                    window.location.href+'?resend_error=true';
                });
        })
    }
}